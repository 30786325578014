<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20a9.97 9.97 0 113.9-.79A9.94 9.94 0 0110 20zm0-18.65a8.62 8.62 0 103.37.68A8.6 8.6 0 0010 1.35z"
    />
    <path
      d="M6.17 12.69a.8.8 0 11.75-.8.77.77 0 01-.75.8m0-2.27a1.47 1.47 0 101.39 1.47 1.43 1.43 0 00-1.39-1.47M13.72 12.69a.8.8 0 11.75-.8.77.77 0 01-.75.8m0-2.27a1.47 1.47 0 101.38 1.47 1.43 1.43 0 00-1.38-1.47"
    />
    <path
      d="M14.52 9.68a.91.91 0 01-.28-.8c.04-.04.86-.03.91.96.02.08-.42.02-.63-.16m-1.94-1.42a.17.17 0 01-.05.11.15.15 0 01-.1.05h-.01a77.05 77.05 0 00-5.78-.27.15.15 0 01-.13-.07.17.17 0 01-.02-.15 4.55 4.55 0 011.09-1.68.15.15 0 01.1-.04s.74-.05 1.4-.05a4.14 4.14 0 011 .07 8.9 8.9 0 012.42 1.54c.04.04.1.09.09.49m-7.16-.14a.15.15 0 01-.1.07l-.32.08a.15.15 0 01-.14-.05.17.17 0 01-.04-.15 3.83 3.83 0 01.85-1.73.16.16 0 01.04-.03 1.62 1.62 0 01.56-.09 1.84 1.84 0 01.2.01.16.16 0 01.13.11.17.17 0 01-.04.17 9.22 9.22 0 00-1.14 1.61m10.3 2.43l-.08-.68c-.02-.2-.24-1.22-1.13-1.36a13.14 13.14 0 00-3.66-2.57 2.9 2.9 0 00-1.5-.3 30.6 30.6 0 00-3.47.1 1.16 1.16 0 00-.98.49 4.9 4.9 0 00-.66 2c0 .53-.1 2.15-.07 2.39a3.24 3.24 0 00-.27 1.27c-.01.1-.01.53.3.53h.19v-.53a1.78 1.78 0 113.55 0v.52h4v-.52a1.78 1.78 0 113.54 0v.5h.12a.44.44 0 00.49-.45 2.29 2.29 0 00-.37-1.39"
    />
  </svg>
</template>
