<script lang="ts" setup>
import { useAuth } from "@/stores/auth";
import { IconBike, IconBus, IconCar, NuxtLink } from "#components";

const auth = useAuth();
const { t } = useI18n();
const localePath = useLocalePath();
const config = useRuntimeConfig();

const shops = computed(() => [
  {
    link: auth.isLoggedIn ? localePath("cards") : localePath("auth-login"),
    external: false,
    name: t("pages.home.label_choice1"),
    icon: IconBus,
  },
  {
    link: "https://freebike.paryelo.fr",
    external: true,
    name: t("pages.home.label_choice2"),
    icon: IconBike,
  },
  {
    link: "https://www.yelomobile.fr/",
    external: true,
    name: t("pages.home.label_choice3"),
    icon: IconCar,
  },
]);

useHead({
  title: t("pages.seo.seo_home"),
  meta: [
    {
      hid: "description",
      name: "description",
      content: t("pages.seo.seo_home_description"),
    },
  ],
});
</script>

<template>
  <div class="mb-32 sm:mb-64">
    <AppBanner :title="$t('pages.home.label_title')" />

    <h2 class="mt-8 px-4 text-2xl sm:mt-12 sm:text-center sm:text-3xl">
      {{ $t("pages.home.label_indications") }}
    </h2>

    <div
      class="container mx-auto mt-8 flex flex-col items-center justify-center overflow-auto px-4 font-semibold md:flex-row"
    >
      <component
        :is="shop.external ? 'a' : NuxtLink"
        v-for="shop of shops"
        :key="shop.icon"
        :href="shop.external ? shop.link : undefined"
        :to="shop.external ? undefined : shop.link"
        class="mt-4 inline-flex w-full max-w-full cursor-pointer items-center rounded-lg bg-primary p-6 text-gray-900 shadow first:mt-0 hover:bg-gray-600 hover:text-gray-100 md:m-1 md:w-64 md:flex-col md:justify-center"
      >
        <component
          :is="shop.icon"
          v-if="shop.icon"
          class="h-10 fill-current md:h-32"
        />
        <p class="ml-6 text-xl md:ml-0 md:mt-2 md:text-2xl">{{ shop.name }}</p>
      </component>
    </div>

    <p class="mt-8 px-4 text-2xl sm:mt-12 sm:text-center sm:text-3xl">
      {{ "Ou" }}
    </p>

    <div
      class="container mx-auto mt-8 flex flex-col items-center justify-center overflow-auto px-4 font-semibold md:flex-row"
    >
      <!-- <a
				href="#"
				class="inline-flex flex-1 items-center w-full max-w-full p-6 mt-4 text-gray-900 rounded-lg shadow cursor-pointer first:mt-0 md:m-1 bg-primary md:flex-col md:justify-center md:w-64 hover:bg-gray-600 hover:text-gray-100"
			>
				<p class="ml-6 text-xl md:ml-0 md:mt-2 md:text-2xl">
					{{ "Payer une amende" }}
				</p>
			</a> -->

      <a
        :href="
          config.public.environment === 'staging'
            ? 'https://passengerweb-yelo.cf-open-payment-staging.flowbird.io/card-login'
            : 'https://passengerweb-yelo.cf-standalone-open-payment.flowbird.io/card-login'
        "
        class="mt-4 inline-flex cursor-pointer items-center rounded-lg bg-primary p-6 text-gray-900 shadow first:mt-0 hover:bg-gray-600 hover:text-gray-100 md:m-1 md:flex-col md:justify-center"
        rel="noopener noreferrer"
        target="_blank"
      >
        <p class="ml-6 text-xl md:ml-0 md:text-2xl">
          {{ "Suivi conso validations par CB" }}
        </p>
      </a>
    </div>
  </div>
</template>