<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20a9.97 9.97 0 113.9-.79A9.94 9.94 0 0110 20zm0-18.73a8.7 8.7 0 103.4.69 8.68 8.68 0 00-3.4-.69z"
    />
    <path
      d="M2.47 10.93A2.73 2.73 0 015.19 8.2a2.4 2.4 0 011.3.33l1-1.53-.2-.65-.68-.28a.27.27 0 01-.17-.26.32.32 0 01.32-.34h1.7a.23.23 0 01.15.4l-.9.39.15.48h4.5l-.21-.63.85-.32c.17-.06.14-.15.11-.22-.05-.16-.43-.15-.6-.15h-.82V5h.81c.56.02.71.04.91.25a.6.6 0 01-.1.89l-.58.23.69 2.07a2.57 2.57 0 011.13-.25 2.73 2.73 0 11-2.72 2.73 2.75 2.75 0 011.2-2.26l-.23-.67-2.6 2.93a.87.87 0 01-.84 1.26l-.12.66.22.04-.06.31-.73-.13.05-.32.2.04.13-.69a.81.81 0 01-.46-.64l-.7-.06a2.73 2.73 0 01-5.42-.45m10.7-1.9a2.34 2.34 0 101.38-.46 2.25 2.25 0 00-1 .23l.25.82a1.78 1.78 0 00.5.89.46.46 0 01.27-.08.49.49 0 01-.02.97.49.49 0 01-.46-.6 2.25 2.25 0 01-.72-1.18zM8 7.16l1.16 3.34a1.08 1.08 0 01.18-.04l.12-.66-.22-.04.04-.3.8.15-.05.3-.24-.04-.1.63a.66.66 0 01.2.1l2.74-3.06-.12-.37zm-1.16 1.6a2.76 2.76 0 011.1 2.21l.67.05a1.12 1.12 0 01.15-.25l-1.1-3.23zm-1.27 1.87a.78.78 0 01.08.15l1.88.16a2.34 2.34 0 00-.92-1.84zm-2.73.3a2.35 2.35 0 002.35 2.35 2.32 2.32 0 002.3-1.92l-1.88-.17a.42.42 0 01-.42.22.48.48 0 01-.48-.48.47.47 0 01.5-.48l1.06-1.59a2.16 2.16 0 00-1.08-.27 2.34 2.34 0 00-2.35 2.34"
    />
  </svg>
</template>
